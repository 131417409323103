import * as React from "react";
import { DefaultProps } from "../utils/defaultProps";
import "../css/Section.scss";
import { EDistrictColor } from "../utils/color";

interface SectionProps extends DefaultProps {
  title: string;
  titleColor: EDistrictColor;
}

const Section = (props: SectionProps) => {
  const {
    id,
    className,
    children,
    title,
    titleColor = EDistrictColor.Darkblue,
  } = props;

  return (
    <div id={id} className={`section ${className}`}>
      <div className="header-background-container">
        <div className="bg" style={{ backgroundColor: titleColor }} />

        <div className="container">
          <h2 className="header">{title}</h2>
        </div>
      </div>

      {children}
    </div>
  );
};

export default Section;
