import * as React from "react";
import "../css/Navbar.scss";

enum NavColor {
  Blue = "blue",
  Green = "green",
  Orange = "orange",
  LightBlue = "lightblue",
}

interface Navlink {
  name: string;
  link: string;
  color: NavColor;
}

const Navbar = () => {
  const navlinks: Navlink[] = [
    { name: "Project", link: "#project", color: NavColor.Blue },
    { name: "Location", link: "#location", color: NavColor.Green },
    { name: "Developer", link: "#developer", color: NavColor.Orange },
    { name: "Contact", link: "#contact", color: NavColor.LightBlue },
  ];

  const nav = (navlinks: Navlink[]) => (
    <nav>
      <ul className="navlinks">
        <li className="gutter"></li>
        {navlinks.map((navlink, i) => (
          <li className="navlink" key={i}>
            <a href={navlink.link} className={`link ${navlink.color}`}>
              <div className="bg"></div>
              {navlink.name}
            </a>
          </li>
        ))}
        <li className="gutter"></li>
      </ul>
    </nav>
  );

  return (
    <div className="navbar">
      <div className="mobile nav-container">{nav(navlinks)}</div>

      <div className="desktop container nav-container">{nav(navlinks)}</div>
    </div>
  );
};

export default Navbar;
