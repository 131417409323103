import * as React from "react";
import { DefaultProps } from "../utils/defaultProps";
import "../css/Layout.scss";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

interface LayoutProps extends DefaultProps {}

const Layout = (props: LayoutProps) => {
  const { className, children } = props;

  return (
    <>
      <Helmet>
        <script>{``}</script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-1F6NL00187"
        ></script>
        <script>
          {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-1F6NL00187');
  `}
        </script>
      </Helmet>

      <div className={`${className} layout`}>
        <Navbar />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
