import * as React from "react";
import { DefaultProps } from "../utils/defaultProps";
import "../css/Signup.scss";

interface SignupProps extends DefaultProps {}

const Signup = (props: SignupProps) => {
  const { id, className } = props;

  return (
    <div id={id} className={`signup ${className}`}>
      <div className="container">
        <div id="mc_embed_signup">
          <form
            action="https://thewilbertgroup.us12.list-manage.com/subscribe/post?u=474b8f3c5908ca62cc8d0a9f8&amp;id=7dd1df889c"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <h2>Stay Connected</h2>
              <div className="mc-field-group">
                <input
                  type="email"
                  placeholder="name@domain.com"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                />
                <input
                  type="submit"
                  value=""
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                />
              </div>
              {/* <div className="clear"> </div> */}
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              {/* </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_474b8f3c5908ca62cc8d0a9f8_7dd1df889c"
                  tabIndex={-1}
                  value=""
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
