import * as React from "react";
import { DefaultProps } from "../utils/defaultProps";
import "../css/Footer.scss";

interface FooterProps extends DefaultProps {}

const Footer = (props: FooterProps) => {
  const { id, className } = props;
  const wycliff = {
    email: "info@wycliffdev.com",
  };
  const liana: any = {
    title: "The Wilbert Group",
    email: "lmoran@thewilbertgroup.com",
    // phone: "111.222.3333",
  };

  return (
    <div id={id} className={`footer ${className}`}>
      <div className="container">
        <div className="content">
          <img className="logo" src="FooterLogo.svg" alt="" />

          <div className="contacts">
            <div className="contact">
              <div className="title">Leasing</div>
              <div className="info">
                <p>Wycliff Development</p>
                <a className="email" href={`mailto:${wycliff.email}`}>
                  {wycliff.email}
                </a>
              </div>
            </div>

            <div className="contact">
              <div className="title">Media Relations</div>
              <div className="info">
                <p>Liana Moran</p>
                <p>{liana.title}</p>
                <a href={`mailto:${liana.email}`} className="email">
                  {liana.email}
                </a>
                {liana.phone && <p>{liana.phone}</p>}
              </div>
            </div>
          </div>

          <span className="website">edistrictnc.com</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
