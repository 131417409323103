import * as React from "react";
import { DefaultProps } from "../utils/defaultProps";
import "../css/Hero.scss";

interface HeroProps extends DefaultProps {}

const Hero = (props: HeroProps) => {
  const { id, className } = props;

  return (
    <div id={id} className={`hero ${className}`}>
      <div className="container">
        <div className="content">
          <div className="hero-image">
            <img src="Hero.svg" alt="E-District Logo" />
          </div>

          <div className="hero-copy">
            Some communities honor their pasts. E&nbsp;District celebrates the
            future. The mixed-use development, a gateway between the Triangle
            and Eastern North Carolina, is about innovation. It is about living
            our best lives through wellness and connection.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
