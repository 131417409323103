import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import Section from "../components/Section";
import Signup from "../components/Signup";
import "../css/index.scss";
import { EDistrictColor } from "../utils/color";

const IndexPage = ({ data }: any) => {
  const wycliffLogo = getImage(data.file);

  return (
    <Layout>
      <div className="homepage">
        <Hero />

        <Section
          id="project"
          className="center-of-opportunity"
          title="Center of Opportunity"
          titleColor={EDistrictColor.Darkblue}
        >
          <div className="container">
            <p>
              E&nbsp;District is a planned 225-acre district that will become a
              center of opportunity, a place for work, life, health and joy.
              Master developer Wycliff Development will incorporate timeless
              components of great communities — a mix of uses, walkability,
              programming and green space — while also creating something wholly
              unique. Because of its large tract of land and sought-after
              location, E&nbsp;District offers an unparalleled opportunity for
              its region.
            </p>
            <p>
              The companies and people who call E&nbsp;District home will
              benefit from proximity to Research Triangle Park, two top-tier
              medical schools (University of North Carolina at Chapel Hill and
              Duke University) and a leading engineering school (North Carolina
              State University). E&nbsp;District is less than seven miles from
              the center of Raleigh, the capital of North Carolina.
            </p>
          </div>
        </Section>

        <Section
          id="location"
          className="location"
          title="Unparalleled Location"
          titleColor={EDistrictColor.Green}
        >
          <img
            className="map"
            src="Location.svg"
            alt="Map showing E-District relative to the interstate"
          />
        </Section>

        <Section
          id="developer"
          className="developer"
          title="Local Developer"
          titleColor={EDistrictColor.Orange}
        >
          <div className="container">
            <div className="content">
              <div className="image-container">
                {wycliffLogo && (
                  <GatsbyImage
                    image={wycliffLogo}
                    alt="Logo for Wycliff Development"
                  />
                )}
              </div>

              <p>
                Wycliff Development, led by longtime North Carolina real estate
                developer Grady Matthews, is the master developer of E{"\u00a0"}
                District. Wycliff is forming best-in-class partnerships,
                including with investors, developers and consultants who have
                track records of success in mixed-use real estate. Matthews, a
                native of Raleigh, spent 25 years in commercial real estate
                before founding Wycliff. He opened the Crosland office in
                Raleigh in 2000, was a partner with Granite Development and most
                recently led the Cushman & Wakefield office for Raleigh-Durham.
              </p>
            </div>
          </div>
        </Section>

        <Signup id="contact" />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    file(relativePath: { eq: "Wycliff_logo_RGB.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default IndexPage;
